var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.orderCreateOrUpdateTypes.includes(_vm.message.type))?_c('div',{staticClass:"px-3 py-1.5 rounded-lg shadow-xl text-sm cursor-pointer",class:_vm.message.userId === _vm.user.banana_id ? 'mr-2 bg-blue-500 text-white' : 'ml-2 bg-white'},[_c('div',{staticClass:"flex"},[_c('span',{staticClass:"flex-1"},[_vm._v("已"+_vm._s(_vm.message.type === "updateDating" ? "修改" : "發起")+"預訂，內容如下：")]),_c('img',{staticClass:"w-[20px]",attrs:{"src":_vm.message.userId === _vm.user.banana_id
                        ? 'img/icons/web/chats/click-finger-black.svg'
                        : 'img/icons/web/chats/click-finger-white.svg',"alt":""}})]),_c('p',[_vm._v("開始時間："+_vm._s(_vm._f("formatDateTime")(_vm.message.startedAt)))]),_c('p',[_vm._v("結束時間："+_vm._s(_vm._f("formatDateTime")(_vm.message.endedAt)))]),_c('p',[_vm._v("會面地點："+_vm._s(_vm.$checkLang(_vm.langDatas, "areas", _vm.message.district))+" | "+_vm._s(_vm.message.location))]),_c('p',[_vm._v("出席費用：$ "+_vm._s(_vm._f("formatCurrency")(_vm.message.price)))])]):_vm._e(),(_vm.extendOrderTypes.includes(_vm.message.type))?_c('div',{staticClass:"px-3 py-1.5 rounded-lg shadow-xl text-sm cursor-pointer",class:_vm.message.userId === _vm.user.banana_id ? 'mr-2 bg-blue-500 text-white' : 'ml-2 bg-white'},[_c('div',{staticClass:"flex"},[_c('span',{staticClass:"flex-1"},[_vm._v(_vm._s(_vm.message.type === "updateExtendOrder" ? "修改" : "提出")+"續約，內容如下：")]),_c('img',{staticClass:"w-[20px]",attrs:{"src":_vm.message.userId === _vm.user.banana_id
                        ? 'img/icons/web/chats/click-finger-black.svg'
                        : 'img/icons/web/chats/click-finger-white.svg',"alt":""}})]),_c('p',[_vm._v("續約時間："+_vm._s(_vm.message.duration)+" 小時")]),_c('p',[_vm._v("結束時間："+_vm._s(_vm._f("formatDateTime")(_vm.message.endedAt)))]),_c('p',[_vm._v("出席費用：$ "+_vm._s(_vm._f("formatCurrency")(_vm.message.price)))])]):_vm._e(),(_vm.cancelOrAcceptExtendOrderTypes.includes(_vm.message.type))?_c('div',{staticClass:"px-3 py-1.5 rounded-lg shadow-xl text-sm mt-3 cursor-pointer",class:_vm.message.userId === _vm.user.banana_id ? 'mr-2 bg-blue-500 text-white' : 'ml-2 bg-white'},[_c('div',{staticClass:"flex"},[_c('span',{staticClass:"flex-1"},[_vm._v(_vm._s(_vm._f("systemString")(_vm.message.type)))]),_c('img',{staticClass:"w-[20px]",attrs:{"src":_vm.message.userId === _vm.user.banana_id
                        ? 'img/icons/web/chats/click-finger-black.svg'
                        : 'img/icons/web/chats/click-finger-white.svg',"alt":""}})])]):_vm._e(),(_vm.cancelOrAcceptOrderTypes.includes(_vm.message.type))?_c('div',{staticClass:"px-3 py-1.5 rounded-lg shadow-xl text-sm cursor-pointer",class:_vm.message.userId === _vm.user.banana_id ? 'mr-2 bg-blue-500 text-white' : 'ml-2 bg-white'},[_c('div',{staticClass:"flex"},[_c('span',{staticClass:"flex-1"},[_vm._v(_vm._s(_vm._f("systemString")(_vm.message.type)))]),_c('img',{staticClass:"w-[20px]",attrs:{"src":_vm.message.userId === _vm.user.banana_id
                        ? 'img/icons/web/chats/click-finger-black.svg'
                        : 'img/icons/web/chats/click-finger-white.svg',"alt":""}})]),(_vm.message.type !== 'acceptOrderByProvider')?_c('p',{staticClass:"break-all"},[_vm._v(" "+_vm._s(_vm.message.type === "cancelOrderByProvider" ? "婉拒" : "取消")+"原因："+_vm._s(_vm.message.note)+" ")]):_vm._e()]):_vm._e(),(_vm.systemOrderTypes.includes(_vm.message.type))?_c('div',{staticClass:"px-3 py-1.5 rounded-lg shadow-xl text-sm mt-3 whitespace-pre cursor-pointer",class:_vm.message.userId === _vm.user.banana_id ? 'mr-2 bg-blue-500 text-white' : 'ml-2 bg-white'},[_c('div',{staticClass:"flex items-start"},[_c('span',{staticClass:"flex-1"},[_vm._v(_vm._s(_vm._f("systemString")(_vm.message.type)))]),_c('img',{staticClass:"w-[20px]",attrs:{"src":_vm.message.userId === _vm.user.banana_id
                        ? 'img/icons/web/chats/click-finger-black.svg'
                        : 'img/icons/web/chats/click-finger-white.svg',"alt":""}})])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }