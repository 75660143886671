<template>
    <div>
        <!-- 聊天室聊天對象資料 -->
        <div v-if="Object.keys(currentChatUserData).length > 0" class="bg-white flex items-center py-3 px-2 rounded-t-lg">
            <div @click="goProfile">
                <Avatar :backgroundImg="chatUser.avatar" :size="['w-10', 'h-10', 'flex-none']" />
            </div>
            <span class="ml-2 flex-1 text-blue-500">
                {{ chatUser.name }}
            </span>
            <button
                v-if="currentChatUserData.isBot && serviceChatId === adminChatId"
                v-permission="['update']"
                class="border border-orange-600 text-sm text-orange-600 px-2 py-0.5 rounded duration-500 mr-3"
                @click="setServiceChatToReal()">
                切換為真人客服
            </button>
            <button
                v-if="!currentChatUserData.isBot && serviceChatId === adminChatId"
                v-permission="['update']"
                class="border border-blue-600 text-sm text-blue-600 px-2 py-0.5 rounded duration-500 mr-3"
                @click="setServiceChatToBot()">
                切換為機器人客服
            </button>
            <!-- <el-dropdown trigger="click"
                         @command="handleCommand">
                <span class="el-dropdown-link mr-3">
                    <span><i class="fas fa-ellipsis-v"></i></span>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="seeUserProfile">查看個人頁面</el-dropdown-item>
                    <el-dropdown-item command="deleteChatRoomMessages">刪除聊天室</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown> -->
        </div>
        <!-- 聊天室內容 -->
        <div ref="chatContent" class="md:max-h-[500px] max-h-screen overflow-y-auto md:min-h-[500px] min-h-screen md:pb-5 pb-[250px] relative">
            <div
                v-for="(message, index) in messages"
                :id="message.key"
                :key="index"
                class="flex mt-3 items-start"
                :class="message.userId === serviceChatId ? 'justify-end' : 'justify-start pl-2'">
                <div @click="goProfile">
                    <Avatar v-if="message.userId !== serviceChatId" :backgroundImg="currentChatUserData.avatar" :size="['w-6', 'h-6', 'flex-none']" />
                </div>
                <!-- 聊天對象顯示日期與已讀方式 -->
                <div v-if="message.userId === serviceChatId" class="text-xs text-gray-300 mx-2 text-right flex-1 self-end">
                    <!-- 已讀 -->
                    <div v-if="currentChatUserData.readedAt > message.createdAt">已讀</div>
                    <!-- 日期 -->
                    <div>{{ message.createdAt | formatMonthAndDayTime }}</div>
                </div>
                <!-- 約會系統訂單顯示方式 -->
                <DatingMessage
                    v-if="datingTypes.includes(message.type)"
                    :currentChatUserData.sync="currentChatUserData"
                    :message="message"
                    @onResetCurrentUserData="resetCurrentUserData"
                    @onSetCurrentChatUserData="setCurrentChatUserData" />
                <!-- 一般聊天內容 -->
                <div
                    v-if="message.type === undefined"
                    :class="
                        message.userId === serviceChatId
                            ? 'whitespace-pre-line bg-blue-500 text-white mr-2 px-3 py-1.5 rounded-lg shadow-xl text-sm max-w-[75%] break-all'
                            : 'whitespace-pre-line bg-white ml-2 px-3 py-1.5 rounded-lg shadow-xl text-sm max-w-[75%] break-all'
                    ">
                    {{ message.content }}
                </div>
                <!-- 與機器人的聊天內容 -->
                <div
                    v-if="message.type === 'bot'"
                    :class="
                        message.userId === serviceChatId
                            ? 'bg-blue-500 text-white mr-2 px-3 py-1.5 rounded-lg shadow-xl text-sm max-w-[75%] break-all'
                            : 'bg-white ml-2 px-3 py-1.5 rounded-lg shadow-xl text-sm max-w-[75%] break-all'
                    ">
                    <div>
                        <div class="whitespace-pre-line">
                            {{ message.content }}
                        </div>
                        <div v-if="!$isEmpty(message.buttons)">
                            <button
                                v-for="(item, key) in message.buttons"
                                :key="key"
                                class="mt-2 bg-white text-orange-600 px-3 py-2 rounded-full max-w-[150px] min-w-[150px] text-sm block mx-auto disabled:cursor-not-allowed"
                                :disabled="true">
                                {{ item.text }}
                            </button>
                        </div>
                    </div>
                </div>
                <!-- 圖片內容 -->
                <div
                    v-if="message.type === 'image'"
                    :class="
                        message.userId === serviceChatId
                            ? 'whitespace-pre-line bg-blue-500 text-white rounded-lg mr-2'
                            : 'whitespace-pre-line bg-white rounded-lg ml-2'
                    "
                    class="leading-none">
                    <silent-box thumbnailHeight="100" thumbnailWidth="100" :image="{ src: message.content }">
                        <template #silentbox-item="{ silentboxItem }">
                            <img class="max-w-[100px] max-h-[100px] rounded-lg p-1" :src="silentboxItem.src" alt="" />
                        </template>
                    </silent-box>
                </div>
                <!-- 自己的聊天內容日期顯示格式 -->
                <span v-if="message.userId !== serviceChatId" class="text-xs text-gray-300 mx-2 self-end">{{
                    message.createdAt | formatMonthAndDayTime
                }}</span>
            </div>
        </div>
        <!-- 刪除聊天室時的彈窗 -->
        <Dialog :showDialog="showDialog" :customClass="'md:left-1/4 top-1/4'" @onCloseDialog="closeConfirm">
            <div class="md:w-[50%] w-full md:p-10 px-5 py-3 bg-white rounded">
                <p>聊天室一但被刪除將無法恢復聊天記錄！ <br />是否刪除聊天室？</p>
                <div class="flex justify-center mt-3 border-t border-gray-100 pt-2">
                    <button class="border border-black text-black px-3 py-0.5 rounded text-sm mr-5" @click.prevent="closeConfirm">取消</button>
                    <button class="bg-orange-600 text-white px-3 py-0.5 rounded text-sm" @click.prevent="deleteChatRoomMessages()">確認</button>
                </div>
            </div>
        </Dialog>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
// 導入 firebase 連接資料庫機制
import { firebaseConnectRef } from "@/plugins/firebase";
/**
 * firebaseSetServiceChatToReal: 切換為真人客服
 * firebaseSetServiceChatToBot: 切換為機器人客服
 * firebaseUnReadCountAndLastMessage: 新增聊天室 未讀訊息數量 與 最後一筆聊天內容
 * firebaseSetReadedTime: 更新聊天對象已讀訊息時間
 * firebaseUserDataUpdate: 更新 users 表未讀訊息數量 以及 updatedAt 時間
 */
import {
    firebaseUnReadCountAndLastMessage,
    firebaseSetServiceChatToReal,
    firebaseSetServiceChatToBot,
    // eslint-disable-next-line no-unused-vars
    firebaseSetReadedTime,
} from "@/service/firebaseChatSet";

// 導入彈窗組件
import Dialog from "@/components/Dialog.vue";
// 導入大頭照組件
import Avatar from "@/components/Avatar.vue";
// 導入系統訊息組件
import DatingMessage from "../cityai/components/DatingMessage.vue";

export default {
    components: {
        Dialog,
        Avatar,
        DatingMessage,
    },
    props: {
        // 客服聊天室id
        serviceChatId: {
            type: String,
            default: null,
        },
        // 當前聊天對象資料
        currentChatUserData: {
            type: Object,
            default() {
                return {};
            },
        },
        // 當前聊天對象id
        currentReceiveUserId: {
            type: [String, Number],
            default: null,
        },
        // 判斷是否為正在讀取歷史訊息
        isReadHistory: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters("chatStore", ["realTimeChatUsers", "realtimeUnReadMessageCount"]),
    },
    data() {
        return {
            // 約會訂單 type
            datingTypes: [
                "extendDating",
                "createDating",
                "updateDating",
                "updateExtendOrder",
                "createExtendOrder",
                "cancelExtendOrderByUser",
                "cancelExtendOrderByProvider",
                "acceptExtendOrderByProvider",
                "cancelOrderByUser",
                "cancelOrderByUserAndFeePay",
                "cancelOrderByProvider",
                "cancelOrderBySystem",
                "acceptOrderByProvider",
                "completeDating",
                "startDating",
            ],
            // 判斷是否顯示刪除聊天室警告彈窗
            showDialog: false,
            // 聊天室訊息
            messages: [],
            // 聊天室單頁資料筆數
            messageLimit: 35,
            // 最後一個 key
            lastMessageKey: null,
            // 起始 key
            firstMessageKey: null,
            // 聊天室查看歷史紀錄最大比數
            maxHistoryMessageLimit: parseInt(process.env.VUE_APP_CHATS_MESSAGE_LIMIT),
            // 判斷是否載入歷史資料中
            loadingHistoryMessage: false,
            // 聊天對象名稱與大頭照
            chatUser: { name: "", avatar: "" },
            // 判斷是否為系統客服
            adminChatId: process.env.VUE_APP_SERVICE_CHAT_ID,
        };
    },
    methods: {
        ...mapActions("chatStore", ["setCurrentReceiveUserMessageReaded"]),
        // 取得聊天訊息列表
        getMessageList() {
            // 執行滾動監聽
            this.windowSrcoll();
            firebaseConnectRef("chats")
                .child(this.serviceChatId)
                .child(this.currentReceiveUserId)
                .orderByKey()
                .limitToLast(this.messageLimit)
                .on("value", async (snapshot) => {
                    // 判斷firebase傳入的聊天對象key 不等同於當前聊天對象時 不執行
                    if (snapshot.key !== this.currentReceiveUserId) {
                        return;
                    }
                    // 判斷是否有資料
                    if (snapshot.val() !== null) {
                        // 將物件資料整理成陣列格式
                        const messages = Object.keys(snapshot.val()).map((objKey) => {
                            const obj = snapshot.val()[objKey];
                            obj.key = objKey;
                            return obj;
                        });
                        // // 判斷當前聊天對象時有未讀訊息時 要清空未讀訊息
                        if (this.currentChatUserData.unReadMessageCount > 0) {
                            // 判斷是否為系統客服聊天對象 (因為 city ai 智能客服預設不清除 服務商底下會員的未讀訊息)
                            if (this.serviceChatId === this.adminChatId) {
                                this.setCurrentReceiveUserMessageReaded({
                                    allUsers: this.realTimeChatUsers,
                                    currentReceiveUserId: this.currentReceiveUserId,
                                    serviceChatId: this.serviceChatId,
                                });
                            }
                        }
                        // 判斷是否為讀取歷史資料
                        if (this.isReadHistory) {
                            /**
                             * 將目前陣列中的聊天資料 與 firebase中最新的聊天資料做比對 看是否有一樣的
                             * 如果沒有一樣的一樣的資料時為 undefined 並將資料新增進入陣列中
                             * 此判斷最主要是避免重複資料塞進陣列中
                             */
                            const message = this.messages.find((item) => item.key === messages[messages.length - 1].key);
                            if (message === undefined) {
                                this.messages.push(messages[messages.length - 1]);
                            }
                        } else {
                            this.messages = messages;
                        }
                        // 記錄聊天記錄key
                        this.lastMessageKey = messages[0].key;
                        // 判斷是否為讀取歷史訊息狀態
                        if (!this.isReadHistory) {
                            // 發送訊息完畢後將視窗滾到最底部
                            this.$nextTick(async () => {
                                if (this.$refs.chatContent !== undefined) {
                                    // 判斷是否為系統客服聊天對象 (因為 city ai 智能客服預設不清除 服務商底下會員的未讀訊息)
                                    if (this.serviceChatId === this.adminChatId) {
                                        // 更新聊天對象已讀訊息時間
                                        await firebaseSetReadedTime(this.currentReceiveUserId, this.serviceChatId);
                                    }
                                    this.$refs.chatContent.scrollTo(0, this.$refs.chatContent.scrollHeight);
                                }
                            });
                        }
                    } else {
                        // 如果聊天室沒有資料須清空 聊天室值
                        this.messages = [];
                    }
                });
        },
        // 取得分頁資料
        getMoreData() {
            // 抓取元素
            const dom = this.$refs.chatContent;
            // 判斷滾動條高度 減掉 視窗高度 在減掉滾動高度 大於 100時才觸發
            if (dom.scrollHeight - dom.clientHeight - dom.scrollTop >= 100) {
                // 判斷此時在觀看 歷史訊息 所以接收到新訊息時 不將視窗網下滾到底
                this.$emit("update:isReadHistory", true);
            }
            // console.log(dom.scrollTop, dom.clientHeight, dom.scrollHeight, dom.offsetTop);
            // dom.offsetTop 與瀏覽器最上方差的高度
            // dom.clientHeight 整個 div 可觀看的高度
            // dom.scrollHeight 整個 div 中內容高度
            // dom.scrollTop 滾動高度

            // 判斷當 滾動距離 加上 當前 div 高度 >= 滾動條高度時 觸發滾動更新分頁
            const getDataStart = dom.scrollHeight - dom.clientHeight === dom.scrollTop;
            // 觸發滾動更新分頁
            if (getDataStart) {
                this.scrollDownGetPagination();
                return;
            }
            // 判斷載入訊息事件未結束時不作動
            if (this.loadingHistoryMessage) {
                return;
            }
            //  當滾動高度 小於或等於 新人榜高度時做觸發
            if (dom.scrollTop <= 0) {
                this.scrollUpGetPrevPagination();
            }
        },
        // 重新取得聊天對象資料
        resetCurrentUserData() {
            // 取消讀取歷史訊息事件
            this.$emit("update:isReadHistory", false);
            this.$emit("onResetCurrentUserData");
        },
        /**
         * 重新設定聊天對象資料
         * @param { type Objcet(物件) } val 設定使用者資料
         */
        setCurrentChatUserData(val) {
            this.$emit("update:currentChatUserData", val);
        },
        // 網上滾動時
        async scrollUpGetPrevPagination() {
            // 判斷載入訊息事件未結束時不作動
            if (this.loadingHistoryMessage) {
                return;
            }
            this.disconnect();
            console.log("網上滾動事件觸發");
            // 網上滾動旋轉動畫結束
            this.loadingHistoryMessage = true;
            try {
                const snapshot = await firebaseConnectRef("chats")
                    .child(this.serviceChatId)
                    .child(this.currentReceiveUserId)
                    .orderByKey()
                    .endBefore(this.lastMessageKey)
                    .limitToLast(this.messageLimit)
                    .once("value");
                // 判斷firebase傳入的聊天對象key 不等同於當前聊天對象時 不執行
                if (snapshot.key !== this.currentReceiveUserId) {
                    // 網上滾動旋轉動畫結束
                    this.loadingHistoryMessage = false;
                    return;
                }
                // 判斷網上滾動時 最高可能讀取過去的訊息數量限制 超過數量 則不往下執行
                if (this.messages.length >= this.maxHistoryMessageLimit) {
                    console.log(this.messages.length, this.maxHistoryMessageLimit, "is work 歷史訊息已達最大限制");
                    // 網上滾動旋轉動畫結束
                    this.loadingHistoryMessage = false;
                    return;
                }
                // 判斷是否有資料
                if (snapshot.val() !== null) {
                    // 將物件資料整理成陣列格式
                    const messages = Object.keys(snapshot.val()).map((objKey) => {
                        const obj = snapshot.val()[objKey];
                        obj.key = objKey;
                        return obj;
                    });
                    // 判斷最後一筆資料 key 與 最新資料的陣列中第一筆 key 相同 則不往下執行 代表沒有最新消息了
                    if (this.lastMessageKey === messages[0].key) {
                        console.log("網上滾動key已是最新");
                        // 網上滾動旋轉動畫結束
                        this.loadingHistoryMessage = false;
                        return;
                    }
                    /**
                     * 須清空陣列時才會用到的機制
                      // 判斷陣列數量大於指定數字時清空 以及最新訊息數量大於 預設每頁比數數量
                      if (this.messages.length > this.messageMaxLimit && messages.length >= this.messageLimit) {
                          this.messages = [];
                      }
                      // 判斷當前聊天對象時有未讀訊息時 要清空未讀訊息
                      if (this.currentChatUserData.unReadMessageCount > 0) {
                          this.setCurrentReceiveUserMessageReaded(
                              { allUsers: this.realTimeChatUsers, currentReceiveUserId: this.currentReceiveUserId }
                          );
                      }
                     */
                    this.messages.unshift(...messages);
                    // 記錄聊天記錄key
                    this.lastMessageKey = messages[0].key;
                    setTimeout(() => {
                        // 網上滾動旋轉動畫結束
                        this.loadingHistoryMessage = false;
                    }, 1500);
                    // 滾到頂部時再往下滾動多少距離
                    this.$nextTick(() => {
                        setTimeout(() => {
                            // 聊天室 dom
                            const dom = this.$refs.chatContent;
                            // 取得最後一筆訊息位置 dom
                            const lastElementId = document.getElementById(messages[messages.length - 1].key);
                            // 晚回滾動至載入歷史訊息前的最後一則訊息位置
                            dom.scrollTo({ top: lastElementId.offsetTop });
                        }, 1000);
                        // this.$refs.chatContent.scrollTo(0, this.$refs.chatContent.offsetTop * 2);
                    });
                } else {
                    // // 網上滾動旋轉動畫結束
                    console.log("已無歷史訊息");
                    this.loadingHistoryMessage = false;
                    // // 如果聊天室沒有資料須清空 聊天室值
                    // this.messages = [];
                }
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "取得歷史資料失敗",
                });
            }
        },
        // 網下滾動時
        async scrollDownGetPagination() {
            console.log("網下滾動事件觸發", this.$refs.chatContent.scrollTop);
            // try {
            //     let snapshot = await firebaseConnectRef("chats")
            //         .child(this.serviceChatId)
            //         .child(this.currentReceiveUserId)
            //         // .orderByChild("createdAt")
            //         .orderByKey()
            //         // .endAt(this.firstMessageKey) // startAt 大於 endAt 小於
            //         .limitToLast(this.messageLimit)
            //         .once("value");
            //     // 判斷firebase傳入的聊天對象key 不等同於當前聊天對象時 不執行
            //     if (snapshot.key !== this.currentReceiveUserId) {
            //         return;
            //     }
            //     // 判斷是否有資料
            //     if (snapshot.val() !== null) {
            //         // 將物件資料整理成陣列格式
            //         let messages = Object.keys(snapshot.val()).map((objKey) => {
            //             let obj = snapshot.val()[objKey];
            //             obj.key = objKey;
            //             return obj;
            //         });
            //         // this.messages = [];
            //         // // // 判斷陣列數量大於指定數字時清空 以及最新訊息數量大於 預設每頁比數數量
            //         // // if (this.messages.length > this.messageMaxLimit && messages.length >= this.messageLimit) {
            //         // //     console.log("清空", messages.length, this.messages.length);
            //         // //     this.messages = [];
            //         // // }

            //         // // 判斷最後一筆資料 key 與 最新資料的陣列中第一筆 key 相同 則不往下執行 代表沒有最新消息了
            //         // // if (this.firstMessageKey === messages[messages.length - 1].key) {
            //         // //     console.log("網下滾動key已是最新");
            //         // //     return;
            //         // // }

            //         // 判斷當前聊天對象時有未讀訊息時 要清空未讀訊息
            //         if (this.currentReceiveUserId.unReadMessageCount > 0) {
            //             this.setCurrentReceiveUserMessageReaded();
            //         }
            //         this.messages.push(...messages);
            //         // 記錄聊天記錄key
            //         this.lastMessageKey = messages[0].key;
            //         console.log(messages, "messages");
            //         // 發送訊息完畢後將視窗滾到最底部
            //         this.$nextTick(() => {
            //             this.$refs.chatContent.scrollTo(0, this.$refs.chatContent.scrollHeight / 2 + this.$refs.chatContent.scrollHeight / 10);
            //         });
            //     } else {
            //         // 如果聊天室沒有資料須清空 聊天室值
            //         this.messages = [];
            //     }
            // } catch (err) {
            //     console.log(err);
            //     this.$message({
            //         type: "error",
            //         message: "取得分頁失敗",
            //     });
            // }
        },
        // 觸發視窗滾動事件
        windowSrcoll() {
            this.$nextTick(() => {
                this.$refs.chatContent.addEventListener("scroll", this.getMoreData);
            });
        },
        /**
         * 關閉刪除聊室警告彈窗
         */
        closeConfirm() {
            // 關閉彈窗
            this.showDialog = false;
            // 關閉彈窗時 取消讀取歷史訊息事件
            this.$emit("update:isReadHistory", false);
        },
        /**
         * 開啟警告刪除聊天室彈窗
         */
        showConfirm() {
            // 開啟彈窗
            this.showDialog = true;
        },
        /**
         * 點擊下拉選單時的事件 (三個點按鈕)
         * @param { type String(字串) } command 下拉選單選擇內容
         */
        handleCommand(command) {
            switch (command) {
                // 查看個人資料
                case "seeUserProfile":
                    break;
                // 刪除聊天室訊息
                case "deleteChatRoomMessages":
                    this.showConfirm();
                    break;
                default:
                    break;
            }
        },
        // 刪除聊天室訊息
        async deleteChatRoomMessages() {
            firebaseConnectRef("chats").child(this.serviceChatId).child(this.currentReceiveUserId).remove();
            this.closeConfirm();
            await firebaseUnReadCountAndLastMessage({ message: "", unReadMessageCount: 0 }, this.serviceChatId, this.currentReceiveUserId);
        },
        // 取消 firebase 監聽
        disconnect() {
            // 判斷是否有 user 資料 因為登出時會清出空
            if (this.serviceChatId !== undefined) {
                // 取消聊天對象未讀訊息數量監聽
                firebaseConnectRef("chat_rooms").child(this.currentReceiveUserId).child("users").child(this.serviceChatId).off();
                // 取消聊天室監聽
                firebaseConnectRef("chats").child("users").child(this.currentReceiveUserId).child("messages").off();
            }
        },
        // 清空聊天室訊息
        clearChatRoomMessages() {
            this.messages = [];
        },
        // 設定客服為真人客服
        async setServiceChatToReal() {
            try {
                await firebaseSetServiceChatToReal(this.currentChatUserData.banana_id, this.serviceChatId);
                await firebaseSetServiceChatToReal(this.serviceChatId, this.currentChatUserData.banana_id);
                this.$emit("onResetCurrentUserData");
            } catch (err) {
                console.log(err);
            }
        },
        // 設定客服為機器人客服
        async setServiceChatToBot() {
            try {
                await firebaseSetServiceChatToBot(this.currentChatUserData.banana_id, this.serviceChatId);
                await firebaseSetServiceChatToBot(this.serviceChatId, this.currentChatUserData.banana_id);
                this.$emit("onResetCurrentUserData");
            } catch (err) {
                console.log(err);
            }
        },
        /**
         * 監聽聊天對象資料
         * @param { type String(字串) } userId 登入使用者 id
         * @param { type String(字串) } currentReceiveUserId 聊天對象 id
         */
        async connectChatUserData(userId, currentReceiveUserId) {
            firebaseConnectRef(`chat_rooms/${userId}/users/${currentReceiveUserId}/userData`).on("value", async (snapshot) => {
                this.chatUser = snapshot.val();
            });
        },
        /**
         * 取消監聽聊天對象資料
         * @param { type String(字串) } userId 登入使用者 id
         * @param { type String(字串) } currentReceiveUserId 聊天對象 id
         */
        disconnectChatUserData(userId, currentReceiveUserId) {
            firebaseConnectRef(`chat_rooms/${userId}/users/${currentReceiveUserId}/userData`).off();
        },
        // 取得聊天對象資料
        goProfile() {
            window.open(`/#/user_list/${this.currentChatUserData.role === 1 ? "provider_update" : "member_update"}/${this.currentChatUserData.id}`);
        },
    },
    watch: {
        // 監聽聊天對象改變時觸發
        async currentReceiveUserId() {
            // 監聽聊天對象
            this.connectChatUserData(this.serviceChatId, this.currentReceiveUserId);
        },
    },
    created() {
        // 取消監聽聊天對象 (因為不取消會監聽太多聊天對象 所以每次監聽新的聊天對象時 先取消舊的)
        this.disconnectChatUserData(this.serviceChatId, this.currentReceiveUserId);
        // 監聽聊天對象
        this.connectChatUserData(this.serviceChatId, this.currentReceiveUserId);
    },
    beforeDestroy() {
        // 取消視窗滾動事件監聽
        this.$refs.chatContent.removeEventListener("scroll", this.getMoreData);
        // this.disconnect();
    },
};
</script>
