<template>
    <div
        class="cursor-pointer bg-blue-200 rounded-full text-blue-500 p-2 flex items-center justify-center bg-cover bg-center shadow-gray-sm relative"
        :class="size"
        :style="`background-image:url(${backgroundImg});`">
        <div v-if="isLogin" :class="['w-16 h-16']" class="border-2 rounded-full border-green-500 p-8"></div>
        <div v-if="isShowLight">
            <span
                v-if="isShowLightPing"
                class="absolute right-0 bottom-1 inline-flex rounded-full h-3 w-3 animate-ping opacity-75"
                :class="[lightColor]"></span>
            <span class="absolute right-0 bottom-1 inline-flex rounded-full h-3 w-3" :class="[lightColor]"></span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        // 頭像圖
        backgroundImg: {
            type: String,
            default: null,
        },
        // 判斷是否呈現燈號
        isShowLight: {
            type: Boolean,
            default: false,
        },
        // 判斷是否呈現閃爍動畫
        isShowLightPing: {
            type: Boolean,
            default: false,
        },
        // 燈號顏色
        lightColor: {
            type: String,
            default: "bg-green-600",
        },
        // 判斷是否為登入狀態
        isLogin: {
            type: Boolean,
            default: false,
        },
        // 頭像尺寸
        size: {
            type: Array,
            default() {
                return ["w-14", "h-14"];
            },
        },
    },
};
</script>
