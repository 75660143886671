/**
 * firebaseChatMessages: 創建聊天內容
 */
import { firebaseChatMessages, firebaseSetReadedTime } from "@/service/firebaseChatSet";
// 導入 firebase 連接資料庫機制
import { firebaseConnectRef } from "@/plugins/firebase";

export default {
    methods: {
        //上傳圖片超過限制數量
        imageOverLimit() {
            this.$message({
                type: "error",
                message: "超過上傳圖片上限",
            });
        },
        /**
         * 圖片變更時
         * @param { type File(檔案) } file 上傳檔案資料
         * @param { type Array(陣列) } fileList 檔案列表資料
         * @returns
         */
        handleFileChange(file) {
            if (file.size > 5 * 1024 * 1024) {
                // 清空檔案上傳列表
                this.fileList = [];
                this.$message({
                    type: "error",
                    message: "圖片尺寸過大",
                });
                return;
            }
            this.uploadAttachments(file);
        },
        // 取得聊天對象未讀數量
        async getReveiveUserUnReadTotal() {
            try {
                const data = await firebaseConnectRef("chat_rooms")
                    .child(this.currentReceiveUserId)
                    .child("users")
                    .child(this.user.banana_id)
                    .once("value");
                if (data == null) {
                    return 0;
                }
                return this.$isEmpty(data.val().unReadMessageCount) ? 0 : data.val().unReadMessageCount;
            } catch (err) {
                console.log(err);
            }
        },
        /**
         * 檔案上傳
         * @param { type File(檔案) } file 檔案資料
         */
        async uploadAttachments(file) {
            try {
                const { data } = await this.$api.ChatUploadAttachmentsApi(file);
                // 發送至firebase 資料
                const sendData = {
                    userId: this.serviceChatId,
                    type: "image",
                    content: data.url,
                    ownerId: this.user.id,
                };
                // 取消讀取歷史訊息事件
                this.$emit("update:isReadHistory", false);
                // 清空檔案列表
                this.fileList = [];
                // 計算未讀訊息
                let count = await this.getReveiveUserUnReadTotal();
                count += 1;
                // 設定 未讀訊息數量 與最後一筆 聊天室內容 (聊天對象)
                this.setUnReadCountAndLastMessage({
                    unReadCount: count,
                    lastMsgAt: this.$moment().valueOf(),
                    lastMessage: "傳送一張照片",
                    loginUserId: this.currentReceiveUserId,
                    receiveUserId: this.serviceChatId,
                    needUpdateUserUnReadMessageCount: this.currentReceiveUserId === process.env.VUE_APP_SERVICE_CHAT_ID ? false : true,
                });
                // 設定 未讀訊息數量 與最後一筆 聊天室內容 (自己)
                this.setUnReadCountAndLastMessage({
                    unReadCount: 0,
                    lastMsgAt: this.$moment().valueOf(),
                    lastMessage: "傳送一張照片",
                    loginUserId: this.serviceChatId,
                    receiveUserId: this.currentReceiveUserId,
                    needUpdateUserUnReadMessageCount: false,
                });
                // 更新聊天對象已讀訊息時間
                await firebaseSetReadedTime(this.currentReceiveUserId, this.serviceChatId);

                // 發送至對方聊天室
                firebaseChatMessages(sendData, this.currentReceiveUserId, this.serviceChatId);
                // 發送至自己聊天室
                firebaseChatMessages(sendData, this.serviceChatId, this.currentReceiveUserId);
            } catch (err) {
                // 清空檔案列表
                this.fileList = [];
                this.$message({
                    type: "error",
                    message: "上傳檔案失敗",
                });
            }
        },
    },
};
