<template>
    <!-- 發送訊息功能 -->
    <div v-if="currentReceiveUserId !== 'all'" class="flex items-center bg-white px-3 py-3 md:mb-0 md:static fixed mb-0 bottom-0 w-full rounded-b-lg">
        <!-- 上傳圖片功能 -->
        <el-upload
            id="chatUpload"
            ref="upload"
            list-type="picture-card"
            :on-change="handleFileChange"
            :auto-upload="false"
            :file-list="fileList"
            :limit="1"
            action=""
            :on-exceed="imageOverLimit"
            class="flex items-center flex-grow-0"
            accept=".jpg,.jpeg,.png,.JPG,.JPEG">
            <img class="cursor-pointer mr-2 h-full" src="img/icons/web/chats/photo-gray.svg" alt="" />
        </el-upload>
        <!-- <img class="cursor-pointer mr-2 "
                             src="~assets/img/icons/web/chats/location-gray.svg"
                             alt=""> -->
        <textarea
            v-model="message"
            class="text-sm pl-3 pt-1 rounded-full border flex-1 border-gray-100 mr-2 min-h-[30px] w-full"
            rows="1"
            @keyup.ctrl.enter="changeLine"
            @keydown.enter.prevent.exact="isSendMessage"></textarea>
        <div class="md:px-5 px-3 cursor-pointer" @click.prevent="isSendMessage">
            <img src="img/icons/web/chats/send-gray.svg" alt="" />
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
// 導入 firebase 連接資料庫機制
import { firebaseConnectRef } from "@/plugins/firebase";
/**
 * firebaseChatMessages: 創建聊天內容
 * firebaseSetReadedTime: 更新聊天對象已讀訊息時間
 */
import { firebaseChatMessages, firebaseSetReadedTime } from "@/service/firebaseChatSet";

// 導入上傳圖片方法
import uploadMixin from "../service/uploadMixin";
export default {
    mixins: [uploadMixin],
    props: {
        // 客服聊天室id
        serviceChatId: {
            type: String,
            default: null,
        },
        // 當前聊天對象資料
        currentChatUserData: {
            type: Object,
            default() {
                return {};
            },
        },
        // 當前聊天對象id
        currentReceiveUserId: {
            type: [String, Number],
            default: null,
        },
    },
    computed: {
        ...mapState("userStore", ["user"]),
        ...mapGetters("chatStore", ["realTimeChatUsers"]),
    },
    data() {
        return {
            // 上傳檔案列表
            fileList: [],
            // 訊息輸入匡
            message: "",
            // 判斷是否為系統客服
            adminChatId: process.env.VUE_APP_SERVICE_CHAT_ID,
        };
    },
    methods: {
        ...mapActions("chatStore", ["getChatUsers", "setUnReadCountAndLastMessage"]),
        ...mapActions("apiStore", ["errorCallback"]),
        /**
         * 點擊 ctrl + enter 時實現換行功能
         * @param { type Event } 瀏覽器的事件
         */
        changeLine() {
            this.message = this.message + "\n";
        },
        /**
         * 發送訊息
         * @param { type Event } 瀏覽器的事件
         */
        async isSendMessage(e) {
            // 判斷是否在選字中 選字狀態下不往下執行
            if (e.isComposing) {
                return;
            }
            // 判斷沒有輸入訊息時 不能做送出
            if (this.message.length < 1) {
                return;
            }
            const data = {
                content: this.message,
                userId: this.serviceChatId,
                ownerId: this.user.id,
            };
            let count = 0;
            try {
                // 計算未讀訊息
                count = await this.getReveiveUserUnReadTotal();
                count += 1;
            } catch (err) {
                console.log(err);
            }

            // 發送訊息時非閱讀歷史訊息狀態 因此將此值改為 false
            this.$emit("update:isReadHistory", false);
            // 將訊息發送給對方 firebase 聊天室資料庫
            this.receiveMessage(data, count);
            // 發送訊息給自己 firebase 聊天室資料庫
            this.sendMessage(data);
            // 清空訊息輸入框
            this.clearnMessage();
            // 發送訊息完畢後將視窗滾到最底部
            this.$nextTick(() => {
                if (this.$refs.chatContent !== undefined) {
                    this.$refs.chatContent.scrollTo(0, this.$refs.chatContent.scrollHeight);
                }
            });
        },
        // 取得聊天對象未讀數量
        async getReveiveUserUnReadTotal() {
            try {
                const data = await firebaseConnectRef("chat_rooms")
                    .child(this.currentReceiveUserId)
                    .child("users")
                    .child(this.serviceChatId)
                    .once("value");

                if (data == null) {
                    return 0;
                }
                return this.$isEmpty(data.val().unReadMessageCount) ? 0 : data.val().unReadMessageCount;
            } catch (err) {
                console.log(err);
            }
        },
        /**
         * 接收訊息對象
         * @param { type Object(物件) } data 聊天室內容
         * @param { type Number(數量) } unReadCount 未讀訊息數量
         */
        async receiveMessage(data, unReadCount) {
            // 發送聊天室訊息
            firebaseChatMessages(data, this.currentReceiveUserId, this.serviceChatId);
            // 設定 未讀訊息數量 與最後一筆 聊天室內容
            this.setUnReadCountAndLastMessage({
                unReadCount,
                lastMsgAt: this.$moment().valueOf(),
                lastMessage: data.content,
                loginUserId: this.currentReceiveUserId,
                receiveUserId: this.serviceChatId,
                // 當聊天對象為客服時 不觸發 更新 users 中指定指定對象未讀訊息數量機制 (因為 cityai 不用理會 服務商中 與客服對話的未讀訊息數量)
                needUpdateUserUnReadMessageCount: this.serviceChatId === process.env.VUE_APP_SERVICE_CHAT_ID ? false : true,
            });
            // 更新聊天對象已讀訊息時間
            await firebaseSetReadedTime(this.currentReceiveUserId, this.serviceChatId);
        },
        /**
         * 發送訊息人員
         * @param { type Object(物件) } data 聊天室內容
         */
        async sendMessage(data) {
            try {
                // 設定 未讀訊息數量 與最後一筆 聊天室內容
                this.setUnReadCountAndLastMessage({
                    unReadCount: 0,
                    lastMsgAt: this.$moment().valueOf(),
                    lastMessage: data.content,
                    loginUserId: this.serviceChatId,
                    receiveUserId: this.currentReceiveUserId,
                    needUpdateUserUnReadMessageCount: false,
                });

                // 發送聊天室訊息
                firebaseChatMessages(data, this.serviceChatId, this.currentReceiveUserId);
                // 判斷為系統客服聊天對象時不執行
                if (this.serviceChatId === this.adminChatId) {
                    return;
                }
            } catch (err) {
                this.errorCallback({ err });
            }
        },
        /**
         * 清空輸入匡訊息內容
         */
        clearnMessage() {
            this.message = "";
        },
    },
};
</script>
<style lang="scss" scoped>
::v-deep #chatUpload {
    .el-upload-list {
        @apply hidden p-0 m-0;
    }
}
::v-deep #chatUpload {
    .el-upload--picture-card {
        @apply w-auto h-auto border-none;
    }
}
</style>
